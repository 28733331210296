import { FC, ReactElement, lazy, Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

const Home = lazy(() => import('@/page/Home'));
const Download = lazy(() => import('@/page/download'));
const TopBar = lazy(() => import('@/components/TopBar'));

const MainRoute: FC = (): ReactElement => {
    return (
        <Suspense fallback={<div></div>}>
            <Switch>
                <TopBar>
                    <Route path="/home" component={Home} />
                    <Route path="/download" component={Download} />
                    <Route exact path="/" render={() => <Redirect to="/home" />} />
                </TopBar>
            </Switch>
        </Suspense>
    );
};
export default MainRoute;
