import { createSlice } from '@reduxjs/toolkit';
import { AppThunk } from '../index';
import { setUserInfo, AccountStatus } from './actions';
import { network, getScatter, getClient } from 'common/utils/client';

const initialState = {
    topBar: {
        title: '',
        goback: '',
    },
    account: {
        kyc: false,
        name: '',
        publicKey: '',
        status: 0,
    },

    coinList: [],
    coinInfo: {},
    chainInfo: {},
    globalCoinLange: 'en_US', 

    themeMode: 'light',
    language: 'en_US',
    chain: {
        eth: 'https://goerli.etherscan.io/',
        btc: 'https://btc.tokenview.com/',
        trx: 'https://nile.tronscan.org/',
    },
    baseChainOption: {
        // 测试网配置
        MBTC: {
            minrecharge: 0.0001,
            minWithdraw: 0.005,
        },
        METH: {
            minrecharge: 0.005,
            minWithdraw: 0.01,
        },
        MUSDT: {
            minrecharge: 1,
            minWithdraw: 50,
        },
        MBNB: {
            minrecharge: 0.001,
            minWithdraw: 0.05,
        }
    },
    transferTime: {
        btc: {
            penddingBlock: 3,
            penddingTime: 1800 // s
        },
        eth: {
            penddingBlock: 12,
            penddingTime: 300 
        },
        bsc: {
            penddingBlock: 12,
            penddingTime: 120 
        },
        tron: {
            penddingBlock: 12,
            penddingTime: 120 
        },
    },
    baseFee: {
        MBTC_btc:{
            isFixedFee: false,  //是否固定收费
            scale: 0.3, // 不是固定收费  按比例%
        },
        METH_eth:{
            isFixedFee: false,
            scale: 0.3,
        },

        MUSDT_eth:{
            isFixedFee: true, 
            scale: 0.3,
        },
        MUSDT_bsc:{
            isFixedFee: true,
            scale: 0.3,
        },
        MBNB_bsc:{
            isFixedFee: true,
            scale: 0.3,
        },
        MUSDT_tron:{
            isFixedFee: true, 
            scale: 0.3,
        },
    },
    browserScanDev: {
        eth: 'https://goerli.etherscan.io/tx/',
        btc: 'https://btc.tokenview.com/btc/tx/',
        tron: 'https://nile.tronscan.org/#/transaction/',
        bsc: 'https://testnet.bscscan.com/tx/',
        amax: 'https://testnet.amaxscan.io/transaction/'
    },
    browserScanMain: {
        eth: 'https://etherscan.io/tx/',
        btc: 'https://www.blockchain.com/btc/tx/',
        tron: 'https://tronscan.org/#/transaction/',
        bsc: 'https://bscscan.com/tx/',
        amax: 'https://amaxscan.io/transaction/'
    },
};

const BaseInfoSlice = createSlice({
    name: 'base',
    initialState,
    reducers: {
        setTopBar(state, { payload: TopBar }) {
            state.topBar = TopBar;
        },
        setTheme(state, { payload: theme }) {
            state.themeMode = theme;
        },
        setLanguage(state, { payload: language }) {
            state.language = language;
        },
        setUserInfo(state, { payload: account }) {
            window.localStorage.setItem('user', JSON.stringify(account));
            state.account = account;
        },
        setCoinList(state, { payload: coinList }) {
            state.coinList = coinList;
        },
        setChainInfo(state, { payload: chainInfo }) {
            state.chainInfo = chainInfo;
        },
        setCoinInfo(state, { payload: coinInfo }) {
            state.coinInfo = coinInfo;
        },
        setGlobalCoinLange(state, { payload: language }) {
            state.globalCoinLange = language;
        },
    },
});

export const getUserInfo = (): AppThunk => async dispatch => {
    try {
        let accountStatus: AccountStatus;
        const scatter: any = getScatter(),
            _getClient: any = await getClient();
        const identity = await scatter.getIdentity({
            accounts: [{ chainId: network.chainId, blockchain: network.blockchain }],
        });
        const account = identity?.accounts[0];
        console.log('====', identity)
        try{
            // 检测账号是否激活   getKeyAccounts(publichKey) 返回的列表如果  name 存在 表示已经激活
            // const accontList = await (window as any).scatterAMAX.getKeyAccounts(identity?.publicKey);
            // console.log('----', accontList)
            // if(accontList?.account_names.indexOf(account.name) !== -1){
            //     accountStatus = 1;
            // }else{
            //     accountStatus = 0;
            // }
            
            await _getClient.getAccount(account.name);
            accountStatus = 1;
        }catch(e){
            console.error('getAccount Error:', e)
            accountStatus = 0;
        }

        dispatch(
            setUserInfo({
                kyc: identity.kyc,
                name: account.name,
                publicKey: identity.publicKey,
                status: accountStatus,
            })
        );
        console.log('----login', identity);
    } catch (e) {
        console.error('login Error:', e);
    }
};

export const getCanchUserInfo = (): AppThunk => async dispatch => {
    try {
        const canche_account = window.localStorage.getItem('user') && JSON.parse(window.localStorage.getItem('user'));
        if(!canche_account) return;
        const scatter: any = getScatter();
        const identity = await scatter.getIdentity({
            accounts: [{ chainId: network.chainId, blockchain: network.blockchain }],
        });
        const account = identity?.accounts[0];
        if(canche_account?.name === account.name){
            dispatch(
                setUserInfo(canche_account)
            );
        }
    } catch (e) {
        console.error('login Error:', e);
    }
};

export default BaseInfoSlice.reducer;
