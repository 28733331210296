import { Route, BrowserRouter, Switch, Redirect } from 'react-router-dom';
import { Provider } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import React, { useEffect, lazy } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import store, { AppState } from './state';
import { getCanchUserInfo, getUserInfo } from '@/state/base/reducer';

import MainRoute from './routeLayout/MainRoute'
import { drak, light } from './theme';


import './locale/main';

const TopBar = lazy(() => import('@/components/TopBar'));

const Providers: React.FC = ({ children }) => {
    return (
        <Provider store={store}>
            {children}
        </Provider>
    );
};

const StyledThemeProvider = props => {
    const dispatch = useDispatch();
    const baseInfo = useSelector((state: AppState) => state.baseInfo);
    useEffect(() => {
        setTimeout(() => {
            dispatch(getUserInfo())
            // dispatch(getCanchUserInfo())
        },1000)
    },[])
    return <ThemeProvider theme={baseInfo.themeMode === 'light' ? light : drak} {...props} />;
};

const Entry: React.ComponentType = () => {
    return (
        <Providers>
            <BrowserRouter>
                <StyledThemeProvider>
                    <Switch>
                        <Route path="/" component={MainRoute} />
                        <Route exact path={'*'} render={() => <Redirect to="/home" />} />
                    </Switch>
                </StyledThemeProvider>
            </BrowserRouter>
        </Providers>
    );
};
export default Entry;
